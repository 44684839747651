<template>
  <div>
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.event}/api/v1/en/moderator/event`"
      :isSearchable="true"
      :parent="parent"
      :modelParent="modelParent"
    ></BreadBrowseTable>
  </div>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/institution";
import model from "@/models/items/institutionEvent";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent"],
  data: () => ({
    //BOC:[tabs]
    tab: null,
    tabs: [
      {
        key: "all",
        label: "Inbox",
      },
      {
        key: "trash",
        label: "Trash",
      },
    ],
    //EOC
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>